import { Controller } from "@hotwired/stimulus"
import * as general from "../general"
import * as helpers from '../helpers'

export default class extends Controller {

  connect() {

  }

  url() {
    return document.querySelector('#vacations').dataset.url;
  }

  rowColData() {
    return document.querySelector('#data').dataset;
  }

  setFocus(row, col) {
    document.querySelector(`[data-row='${row}'][data-col='${col}']`).focus();
  }

  onKeyUp(event) {
    var data = event.currentTarget.dataset;
    var row = parseInt(data.row);
    var col = parseInt(data.col);
    
    var total = this.rowColData();
    var rows = parseInt(total.rows);
    var cols = parseInt(total.cols);

    if(event.code == 'Enter') {      
        this.save(event);
    }

    if(event.code == 'Enter') {
        this.setFocus( (row + 1) % rows, col );
    } else if(event.code == 'ArrowLeft') {
        this.setFocus( row, (col - 1 + cols) % cols );
    } else if(event.code == 'ArrowRight') {
        this.setFocus( row, (col + 1) % cols );
    }
  }

  toNumber(num) {
    var num_i = parseInt(num);
    return num_i == num ? num_i : num;
  }

  save(event) {
    var self = this;
    var el = event.currentTarget;
    var d = JSON.parse(el.dataset.obj);
    var val = helpers.getValue(d.type, el, d.old);

    if(!helpers.isValueChanged(d.old, val)) {
      return;
    }

    var data = {
      user_id: d.user_id,
      year: d.year, 
      days: val
    };

    var success = function(result) {
      d.old = val;
      el.dataset.obj = JSON.stringify(d);

      // general.successSnack('Uspešno shranjeno!');
    };

    general.postJson(this.url(), { vacation: data }, success);
  }

  edit(event) {
    var self = this;
    var el = event.currentTarget;
    var obj = JSON.parse(el.dataset.obj);
    var years = JSON.parse( document.querySelector('#vtable').dataset.years );
    const formId = general.randomString();

    const onSave = function() { 
      let json = general.formToObject(document.getElementById(formId));
      for(let year of years) {
        var extended = json[year] == '1'

        if((extended && obj.extended.indexOf(year) >= 0) || (!extended && obj.extended.indexOf(year) < 0)) {
          continue;
        }

        var data = {
          user_id: obj.user_id,
          year: year,
          extended: extended
        };

        const success = function(result) {
          obj.extended = result.extended;
          el.dataset.obj = JSON.stringify(obj);

          modal.hide();
        };

        general.postJson(self.url(), { vacation: data }, success);
      }
    };

    var modal = general.modal(`Podaljšana možnost koriščenja`, this.form(formId, obj, years), onSave);
  }

  form(formId, obj, years) {
    var html = `<form class="needs-validation" id="${formId}" novalidate>
      <input type="hidden" name="user_id" value="${obj.user_id}" />
    `;
    for(var year of years) {
      var checkedProp = obj.extended.indexOf(year) >= 0 ? "checked='checked'" : "";
      html += `
        <div class="form-check">
          <input class="form-check-input" type="checkbox" name="${year}" value="1" id="y${year}" ${checkedProp}>
          <label class="form-check-label" for="y${year}">
            ${year}
          </label>
        </div>
      `;
    }
    html += "</form>";
    return html;
  }
}