import { Controller } from "@hotwired/stimulus"
import * as general from "../general"
import * as helpers from '../helpers'

export default class extends Controller {

  connect() {
    
  }

  setColors(el, color, text_color) {
    if(el && color) {
      el.style = `background-color: ${color}; color: ${text_color};`;
    }
  }

  edit(event) {
    var self = this;
    const data = event.currentTarget.dataset;
    const formId = general.randomString();
    const obj = JSON.parse(data.obj)

    const onSave = function() { 
      let json = general.formToObject(document.getElementById(formId));
      const success = function(result) {
        var el = document.querySelector(`#project-${result.id}`);
        helpers.setHtml(el, result.name);
        self.setColors(el, result.color, result.text_color);
        data.obj = JSON.stringify(result);

        if(result.active) {
          helpers.removeClass(el, 'text-decoration-line-through');
        } else {
          helpers.addClass(el, 'text-decoration-line-through');
        }

        // general.successSnack("Projekt uspešno spremenjen!");
        modal.hide();

        self.userProjectController.refreshTable();
      };
      general.putJson(data.url, { project: json }, success, self.error);
    };

    var modal = general.modal(`Uredi projekt`, this.form(formId, obj), onSave);
  }

  form(formId, obj) {
    obj = obj || { active: true };
    const whiteTextCheckedProp = obj.white_text ? "checked='checked'" : "";
    const blackTextCheckedProp = obj.white_text ? "" : "checked='checked'";
    const activeCheckedProp = obj.active ? "checked='checked'" : "";
    return `
      <form class="needs-validation" id="${formId}" novalidate>
        <div class="mb-3">
          <label for="name" class="form-label">Ime</label>
          <input type="text" class="form-control" name="name" id="name" value="${obj.name || ''}">
        </div>
        <div class="mb-3">
          <div class="row">
            <div class="col">
              <label for="color" class="form-label">Barva ozadja</label>
              <input type="color" name='color' class="form-control form-control-color" id="color" value="${obj.color || '#ffffff'}" title="Izberi barvo ozadja">
            </div>
            <div class="col">
              <label class="form-label">Barva pisave</label>
              <br>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="white_text" id="white_text_false" value="false" ${blackTextCheckedProp}>
                <label class="form-check-label" for="white_text_false">Črna</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="white_text" id="white_text_true" value="true" ${whiteTextCheckedProp}>
                <label class="form-check-label" for="white_text_true">Bela</label>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" name="active" value="true" id="active" ${activeCheckedProp}>
            <label class="form-check-label" for="active">
              Aktiven?
            </label>
          </div>
        </div>
      </form>
    `;
  }

  error(result) {
    helpers.removeAll('.invalid-feedback');
    helpers.removeClassFromAll('.is-invalid', 'is-invalid');
    
    for(let key in result) {
      let input = document.querySelector(`[name="${key}"]`);
      helpers.addClass(input, 'is-invalid');
      helpers.addErrorDivAfter(input, result[key][0]);
    }
  }

  new(event) {
    var self = this;
    const data = event.currentTarget.dataset;
    const formId = general.randomString();

    const onSave = function() { 
      let json = general.formToObject(document.getElementById(formId));

      const success = function(result) {
        var table = document.querySelector('#user-projects-table');
        var rows = table.querySelectorAll('.css-table-row');
        var firstRow = rows[0];
        var secondRow = rows[1];
        var rowsCount = rows.length;
        var firstRowCells = rows[0].querySelectorAll('.css-table-cell'); 
        var secondRowCells = rows[1].querySelectorAll('.css-table-cell'); 
        var colsCount = firstRowCells.length;

        var th1 = document.createElement('div');
        helpers.addClass(th1, 'css-table-cell fw-bold text-center');
        helpers.setHtml(th1, result.name);
        helpers.setAttribute(th1, "id", `project-${result.id}`);
        helpers.setAttribute(th1, "data-project_id", result.id);
        helpers.insertAfter(firstRowCells[colsCount-1], th1);
        self.setColors(th1, result.color, result.text_color);

        var th2 = document.createElement('div');
        helpers.addClass(th2, 'css-table-cell fw-bold text-center');
        var th2Html = `
          <a data-action="click->project#edit" data-url="/projects/${result.id}.json" data-obj='${JSON.stringify(result)}' class="icon-link" href="#">
            <i class="fas fa-edit"></i>
          </a>
        `;
        helpers.setHtml(th2, th2Html);
        helpers.insertAfter(secondRowCells[colsCount-1], th2);

        for(var i = 2; i < rowsCount; i++) {
          var ithRowCells = rows[i].querySelectorAll('.css-table-cell');
          var userId = ithRowCells[0].getAttribute('data-user_id');
          var cell = ithRowCells[colsCount-1];

          var td = document.createElement('div');
          helpers.addClass(td, 'css-table-cell text-center');
          var tdHtml = `<input type="checkbox" name="${userId}|${result.id}" id="${userId}_${result.id}" value="1" class="form-check-input user-project-check" data-action="change->user_project#update" data-user_id="${userId}" data-project_id="${result.id}">`;
          helpers.setHtml(td, tdHtml);
          helpers.insertAfter(cell, td);
        }

        // general.successSnack("Projekt uspešno dodan!");
        modal.hide();
      };

      general.postJson(data.url, { project: json }, success, self.error);
    };

    var modal = general.modal(`Dodaj nov projekt`, this.form(formId), onSave, );
  }

  get userProjectController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "user_project");
  }
}