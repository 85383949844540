import { Controller } from "@hotwired/stimulus"
import * as general from "../general"
import * as helpers from '../helpers'
import * as bootstrap from 'bootstrap'

export default class extends Controller {

  connect() {
    this.refreshAll();
  }

  initTooltips() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  refreshAll() {
    // total by row
    var rows = parseInt(this.rowColData().rows);
    for(var row of [...Array(rows).keys()]) {
      this.refreshRow(row);
    }

    // total by col
    var cols = parseInt(this.rowColData().cols);
    for(var col of [...Array(cols).keys()]) {
      this.refreshCol(col);
    }

    // total total
    var totaltotal = Array.from(document.querySelectorAll(`[data-col="total"]`)).reduce( (sum, el) => sum + (numeral(el.innerText).value() || 0), 0);
    helpers.setHtml(document.querySelector(`[data-row='total'][data-col='total']`), helpers.toNumber(totaltotal));

    // init tooltips
    this.initTooltips();
  }

  refreshRow(row) {
    var n = parseInt(this.rowColData().rows);
    var total = Array.from(document.querySelectorAll(`[data-row="${row}"]`)).reduce( (sum, el) => sum + (numeral(el.innerText).value() || 0), 0);    
    helpers.setHtml(document.querySelector(`[data-col='total'][data-row='${row}']`), helpers.toNumber(total));
  }

  refreshCol(col) {
    var n = parseInt(this.rowColData().cols);
    var total = Array.from(document.querySelectorAll(`[data-col="${col}"]`)).reduce( (sum, el) => sum + (numeral(el.innerText).value() || 0), 0);
    helpers.setHtml(document.querySelector(`[data-row='total'][data-col='${col}']`), helpers.toNumber(total));
  }

  goTo(event, direction) {
    var parent = event.currentTarget.parentNode;
    var el = parent[`${direction}ElementSibling`];

    helpers.addClass(parent, 'hide');
    helpers.removeClass(el, 'hide');
    helpers.addClass(el, 'active');
    helpers.removeClass(parent, 'active');

    this.refreshTable();
  }

  url() {
    return document.querySelector('#reports').dataset.url;
  }

  projectId() {
    return document.querySelector('#project-select').value;
  }

  organisationId() {
    return document.querySelector('#organisation-select').value;
  }

  employedOnly() {
    return document.querySelector('#employed_only').checked;
  }

  studentOnly() {
    return document.querySelector('#student_only').checked;
  }

  showComments() {
    return document.querySelector('#show_comments').checked;
  }

  refreshTable() {
    var self = this;
    var monthData = JSON.parse(document.querySelector('.month.active').dataset.obj);
    var data = {
      month: monthData.month,
      year: monthData.year,
      project_id: this.projectId(),
      organisation_id: this.organisationId(),
      employed_only: this.employedOnly(),
      student_only: this.studentOnly(),
      show_comments: this.showComments() 
    };

    var query = new URLSearchParams(data).toString();

    var success = function(result) {
      helpers.setHtml(document.querySelector('#table-holder'), result.html);
      self.refreshAll();
    };

    general.getJson(`${this.url()}?${query}`, success);
  }

  prev(event) {
    this.goTo(event, 'previous');
  }

  next(event) {
    this.goTo(event, 'next');
  }

  rowColData() {
    return document.querySelector('#data').dataset;
  }
}