import { Controller } from "@hotwired/stimulus"
import * as general from "../general"
import * as helpers from '../helpers'

export default class extends Controller {

  connect() {
    
  }

  projectFilterChanged(event) {
    this.refreshTable();
  }

  // organisationFilterChanged(event) {
  //   this.refreshTable();
  // }

  refreshTable() {
    var self = this;
    // var organisationId = document.querySelector('#organisation-select').value;
    var projectId = document.querySelector('#project-select').value;
    var data = {
      // organisationId,
      projectId
    };

    var query = new URLSearchParams(data).toString();

    var success = function(result) {
      helpers.setHtml(document.querySelector('#table-holder'), result.html);
    };

    general.getJson(`${this.url()}?${query}`, success);
  }

  showUserPhoto(event) {
    const obj = JSON.parse( event.currentTarget.dataset.obj );
    var modal = general.modalWithoutButtons(obj.title, `<div class="row"><div class="col-12"><img src="${obj.photo_url}" style="max-width: 100%;" /></div></div>`);
    event.preventDefault();
  }

  url() {
    return document.querySelector('#users').dataset.url;
  }

  organisationsDropdown(organisations, selectedId) {
    let s = '<select name="organisation_id" id="organisation" class="form-select">';
    s += `<option value="">Izberi organizacijo...</option>`;
    for( let { id, name } of organisations) {
      let selected = selectedId && id == parseInt(selectedId) ? ' selected="selected"' : "";
      s += `<option value="${id}" ${selected}>${name}</option>`;
    }
    s += "</select>";
    return s;
  }

  edit(event) {
    var self = this;
    const data = event.currentTarget.dataset;
    const formId = general.randomString();
    const obj = JSON.parse(data.obj)
    const organisations = JSON.parse(data.organisations);

    const onSave = function() { 
      let json = general.formToObject(document.getElementById(formId));
      const success = function(result) {
        var span = document.querySelector(`#user-${result.id}`);
        if(result.active) {
          helpers.removeClass(span.parentNode, 'text-decoration-line-through');
        } else {
          helpers.addClass(span.parentNode, 'text-decoration-line-through');
        }

        if(result.employed) {
          helpers.addClass(span, 'fw-bold');
        } else {
          helpers.removeClass(span, 'fw-bold');
        }

        if(result.admin) {
          helpers.addClass(span, 'text-gold');
        } else {
          helpers.removeClass(span, 'text-gold');
        }

        if(result.student) {
          result.full_name += " (Š)";
        }

        helpers.setHtml(span, result.full_name);
        data.obj = JSON.stringify(result);
        // general.successSnack("Oseba uspešno spremenjena!");
        modal.hide();

        self.userProjectController.refreshTable();
      };

      let fd = new FormData();
      for(let key in json) {
        fd.append(`user[${key}]`, json[key]);
      }

      general.putJson(data.url, fd, success, self.error, true);
    };

    var modal = general.modal(`Uredi osebo`, this.form(formId, organisations, obj), onSave);
  }

  form(formId, organisations, obj) {
    obj = obj || { active: true };
    const DEFAULT_ARRIVAL_TIME = '09:00';
    const activeCheckedProp = obj.active ? "checked='checked'" : "";
    const employedCheckedProp = obj.employed ? "checked='checked'" : "";
    const studentCheckedProp = obj.student ? "checked='checked'" : "";
    const adminCheckedProp = obj.role & 1 == 1 ? "checked='checked'" : "";
    const defaultArrivalTimeHtml = obj.employed || obj.student ? `
        <div class="mb-3">
          <label for="default_time_of_arrival" class="form-label">Privzet čas prihoda</label>
          <input type="time" class="form-control" name="default_time_of_arrival" id="default_time_of_arrival" value="${obj.default_time_of_arrival || DEFAULT_ARRIVAL_TIME}">
        </div>
    ` : "";
    const hasPhoto = !!obj.photo_url;
    const nameKlass = hasPhoto ? 'col-8' : 'col-12';
    const photoHtml = hasPhoto ? `<div class="col-4"><img src="${obj.photo_url}" style="max-width: 100%;" /></div>` : "";
    return `
      <form class="needs-validation" id="${formId}" novalidate>
        <div class="row">
          <div class="${nameKlass}">
            <div class="mb-3">
              <label for="firstName" class="form-label">Ime</label>
              <input type="text" class="form-control" name="first_name" id="firstName" value="${obj.first_name || ''}">
            </div>
            <div class="mb-3">
              <label for="lastName" class="form-label">Priimek</label>
              <input type="text" class="form-control" name="last_name" id="lastName" value="${obj.last_name || ''}">
            </div>
          </div>
          ${photoHtml}
        </div>
        <div class="mb-3">
          <label for="organisation" class="form-label">Organizacija</label>
          ${this.organisationsDropdown(organisations, obj.organisation_id)}
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input type="text" class="form-control" name="email" id="email" value="${obj.email || ''}">
        </div>
        <div class="mb-3">
          <label for="report_email" class="form-label">Email za poročanje</label>
          <input type="text" class="form-control" name="report_email" id="report_email" value="${obj.report_email || ''}">
        </div>
      ` + defaultArrivalTimeHtml + `
        <div class="mb-3">
          <label for="photo" class="form-label">Slika</label>
          <input type="file" class="form-control" name="photo" id="photo" />
        </div>
        <div class="row">        
          <div class="col">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" name="active" value="1" id="active" ${activeCheckedProp}>
              <label class="form-check-label" for="active">
                Aktiven?
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" name="employed" value="1" id="employed" ${employedCheckedProp}>
              <label class="form-check-label" for="employed">
                Zaposlen?
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" name="student" value="1" id="student" ${studentCheckedProp}>
              <label class="form-check-label" for="student">
                Študent?
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" name="role" value="1" id="admin" ${adminCheckedProp}>
              <label class="form-check-label" for="admin">
                Admin?
              </label>
            </div>
          </div>
        </div>
      </form>
    `;
  }

  error(result) {
    helpers.removeAll('.invalid-feedback');
    helpers.removeClassFromAll('.is-invalid', 'is-invalid');
    
    for(let key in result) {
      let input = document.querySelector(`[name="${key}"]`);
      helpers.addClass(input, 'is-invalid');
      helpers.addErrorDivAfter(input, result[key][0]);
    }
  }

  new(event) {
    var self = this;
    const data = event.currentTarget.dataset;
    const formId = general.randomString();
    const organisations = JSON.parse(data.organisations);

    const onSave = function() { 
      let json = general.formToObject(document.getElementById(formId));

      const success = function(result) {
        var table = document.querySelector('#user-projects-table');
        var rows = table.querySelectorAll('.css-table-row');
        var lastRow = rows[rows.length - 1];
        var cells = rows[0].querySelectorAll('.css-table-cell'); 
        var cols = cells.length;

        var klass = result.active ? "" : "text-decoration-line-through";
        var spanKlass = "";
        spanKlass += result.employed ? " fw-bold" : "";
        spanKlass += result.admin ? " text-gold" : "";

        if(result.student) {
          result.full_name += " (Š)";
        }

        var newRow = document.createElement('div');
        helpers.addClass(newRow, 'css-table-row');
        var newRowHtml = `
          <div data-user_id="${result.id}" class="${klass} css-table-cell sticky-col">
            <span id="user-${result.id}" class="${spanKlass}">${result.full_name}</span>
            <a data-action="click->user#edit" data-obj='${JSON.stringify(result)}' data-url="/users/${result.id}.json" class="icon-link" href="#">
              <i class="fas fa-user-edit ps-2"></i>
            </a> 
          </div>
        `;
        for(var i = 1; i < cols; i++) {
          var cell = cells[i];
          var projectId = cell.getAttribute('data-project_id');
          newRowHtml += `
            <div class="text-center css-table-cell">
              <input type="checkbox" name="${result.id}|${projectId}" id="${result.id}_${projectId}" value="1" class="form-check-input user-project-check" data-action="change->user_project#update" data-user_id="${result.id}" data-project_id="${projectId}">
            </div>
          `;
        }
        helpers.setHtml(newRow, newRowHtml);
        helpers.insertAfter(lastRow, newRow);

        // general.successSnack("Oseba uspešno dodana!");
        modal.hide();
      };

      let fd = new FormData();
      for(let key in json) {
        fd.append(`user[${key}]`, json[key]);
      }

      general.postJson(data.url, fd, success, self.error, true);
    };

    var modal = general.modal(`Dodaj novo osebo`, this.form(formId, organisations), onSave, );
  }

  get userProjectController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "user_project");
  }
}
